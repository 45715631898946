import React from 'react';
import PageLayout from '@global/page-layout';
import { useStaticQuery, graphql } from 'gatsby';
import CasePreview from '@containers/CasePreview';
import '../../assets/styles/tailwind.css';
import { popup } from '@src/global/utils/typeformPopup';
import CTA3 from '@containers/CTA3';

const CaseStudies = () => {
  const data = useStaticQuery(graphql`
    query allCaseStudiesSectionJson {
      caseStudiesSectionJson {
        title
        subTitle
        cases {
          title
          description
          image
          link
          categories
          status
          type
        }
      }
    }
  `);

  const { title, subTitle, cases } = data.caseStudiesSectionJson;

  return (
    <PageLayout title="Case Studies">
      <section className="text-gray-700 body-font bg-gray-100 pt-6">
        <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-col">
            <div className="h-1 bg-gray-300 rounded overflow-hidden">
              <div className="w-24 h-full bg-primaryColor"></div>
            </div>
            <div className="flex flex-wrap sm:flex-row flex-col py-6 mb-12">
              <h1 className="sm:w-2/5 text-bodyColor font-medium title-font text-2xl mb-2 sm:mb-0">
                {title}
              </h1>
              <p className="sm:w-3/5 leading-relaxed sm:pl-10 pl-0">
                <span className="font-semibold">
                  What do these businesses have in common?{' '}
                </span>{' '}
                {subTitle}
              </p>
            </div>
          </div>
          <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4">
            {cases.map((caseStudy, index) => (
              <CasePreview caseStudy={caseStudy} key={index} />
            ))}
          </div>
        </div>
      </section>
      <CTA3 onPress={() => popup()} />
    </PageLayout>
  );
};

export default CaseStudies;
