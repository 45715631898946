import styled from 'styled-components';

const ImageContainer = styled.div.attrs({
  className: 'rounded-lg h-64 overflow-hidden',
})`
  position: relative;
`;

const BadgeContainer = styled.div.attrs({
  className:
    'inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium leading-5 bg-green-100 text-green-800',
})`
  position: absolute;
  right: 0;
  top: 0;
  text-align: center;
  padding: 5px 10px;
  margin: 5px;
`;

export { ImageContainer, BadgeContainer };
