import React from 'react';
import PropTypes from 'prop-types';
import '../../assets/styles/tailwind.css';
import { Link } from 'gatsby';
import { ImageContainer, BadgeContainer } from './casepreview.style';

const CasePreview = ({ caseStudy }) => (
  <div className="p-4 md:w-1/3 sm:mb-0 mb-6">
    <ImageContainer>
      <BadgeContainer>{caseStudy.type}</BadgeContainer>
      <img
        alt="content"
        className="object-cover object-center h-full w-full"
        src={caseStudy.image}
      />
    </ImageContainer>
    <div className="flex space-x-4 flex-wrap sm:flex-row py-6 mb-2">
      {caseStudy.categories.map((category, index) => (
        <span
          className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium leading-5 bg-indigo-100 text-indigo-800"
          key={index}
        >
          {category}
        </span>
      ))}
    </div>
    <h2 className="text-xl font-medium title-font text-gray-900 mt-0">
      {caseStudy.title}
    </h2>
    <p className="text-base leading-relaxed mt-2">{caseStudy.description}</p>
    {caseStudy.status !== 'coming-soon' ? (
      <Link
        to={`${caseStudy.link}`}
        className="hover:outline-none hover:underline text-xl text-green-500 inline-flex items-center mt-3"
      >
        View case study
        <svg
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          className="w-4 h-4 ml-2"
          viewBox="0 0 24 24"
        >
          <path d="M5 12h14M12 5l7 7-7 7"></path>
        </svg>
      </Link>
    ) : (
      <p className="hover:outline-none text-xl text-green-500 inline-flex items-center mt-3">
        Coming soon
      </p>
    )}
  </div>
);

CasePreview.propTypes = {
  caseStudy: PropTypes.object.isRequired,
};

export default CasePreview;
