import React from 'react';
import PropTypes from 'prop-types';
import { TWButton } from '@styled/components';

const CTA3 = ({ onPress, gray }) => (
  <div className={gray ? 'bg-gray-200' : 'bg-white'}>
    <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
      <h2 className="text-3xl leading-9 font-medium tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
        Ready to dive in?
        <br />
        <span className="text-green-500 font-normal">
          Start your free 14 day trial today.
        </span>
      </h2>
      <div className="mt-8 flex lg:flex-shrink-0 lg:mt-0">
        <div className="inline-flex rounded-md shadow">
          <TWButton
            className="inline-flex items-center justify-center px-5 py-3 border border-transparent border-solid border-2 border-primaryColor hover:border-primaryColor text-base leading-6 font-medium rounded-md text-white bg-primaryColor hover:text-primaryColor hover:bg-white transition duration-150 ease-in-out"
            onClick={() => onPress()}
            title="Get started"
          />
        </div>
      </div>
    </div>
  </div>
);

CTA3.propTypes = {
  onPress: PropTypes.func.isRequired,
  gray: PropTypes.bool,
};

export default CTA3;
