import * as typeformEmbed from '@typeform/embed';
import { navigate } from 'gatsby';

export function popup(
  typeformLink = 'https://picortex.typeform.com/to/NBpm8D'
) {
  if (!typeformLink.includes('typeform')) {
    navigate(typeformLink);
  } else {
    const popup1 = typeformEmbed.makePopup(typeformLink, {
      hideHeaders: true,
      hideFooter: true,
    });
    popup1.open();
  }
}
